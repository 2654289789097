
<script>
// import appConfig from "@/app.config";

// import { fabric } from "fabric";

// import SellingProduct from './selling-product';
// import TopUsers from './top-users';
// import Activity from './activity';
// import SocialSource from './social-source';

export default {
  page: {
    title: "LIFF",
    // meta: [
    //   {
    //     name: "description",
    //     content: appConfig.description,
    //   },
    // ],
  },
  components: {
  },
  data() {
    return {
      user:'',
      title: "LIFF",
      branchId: "",
      localDataBranch: "",
      dataDashboard:'',
      DateSearch: "",
      localData: [],
      profile: "",
      items: [
        {
          text: "SuriyaIT",
        },
        {
          text: "LIFF",
          active: true,
        },
      ],
    };
  },
  created() {
    // this.getLocalData();
  },
  mounted(){
      
  },
  methods: {

  },
};
</script>

<template>
<div>
  <div class="row">
      <div class="col-md-12 text-center">
        สวัสดี !
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center mt-4">
        <b-avatar :src="profile.pictureUrl" size="6rem"></b-avatar>
      </div>
      <div class="col-md-12 text-center">
       ชื่อลูกค้า : {{ profile.displayName }}
      </div>
      <div class="col-md-12 text-center">
        userId : {{ profile.userId }}
      </div>
      
    </div>
</div>
    
    
</template>
